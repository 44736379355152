<app-card
  [mfaSetup]="getSignInState() === SIGN_IN_STATE.MFA_SETUP"
  [systemAccessNotification]="getSignInState() === SIGN_IN_STATE.MFA_SETUP ? '' : markdown"
  [subHeading]="flowLabel(getSignInState())"
>
  @switch (getSignInState()) {
    @case (SIGN_IN_STATE.SIGN_IN) {
      <app-sign-in
        [userName]="userName"
        [clientId]="clientId"
        (setSignInState)="setSignInState($event)"
        (signInOuput)="signInOutput = $event"
      />
    }
    @case (SIGN_IN_STATE.FORGOT_PASSWORD) {
      <app-forgot-password
        [userName]="userName"
        (setSignInState)="setSignInState($event)"
        (setResetFlowUsername)="setResetFlowUsername($event)"
      />
    }
    @case (SIGN_IN_STATE.CONFIRM_SIGN_IN) {
      <app-reset-password
        [resetWithCode]="false"
        [clientId]="clientId"
        (setSignInState)="setSignInState($event)"
        (signInOuput)="signInOutput = $event"
        (routeToApplicationGettingDefaultOrgDetails)="routeToApplicationGettingDefaultOrgDetails()"
      />
    }
    @case (SIGN_IN_STATE.RESET_PASSWORD) {
      <app-reset-password
        [resetWithCode]="true"
        [userName]="getResetFlowUsername()"
        (setSignInState)="setSignInState($event)"
      />
    }
    @case (SIGN_IN_STATE.MFA_SETUP) {
      <app-mfa-setup
        (setSignInState)="setSignInState($event)"
        [totpSetupDetails]="signInOutput"
        (routeToApplicationGettingDefaultOrgDetails)="routeToApplicationGettingDefaultOrgDetails()"
      />
    }
    @case (SIGN_IN_STATE.MFA_PROMPT) {
      <app-mfa-prompt
        (setSignInState)="setSignInState($event)"
        (routeToApplicationGettingDefaultOrgDetails)="routeToApplicationGettingDefaultOrgDetails()"
      />
    }
  }
</app-card>
