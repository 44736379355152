import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';

import {CustomInputComponent} from '../../../components/custom-input/custom-input.component';
import {ButtonComponent} from '../../../components/button/button.component';
import {LABEL_CONSTANT, LOGIN_CONSTANT} from '../../../constant/constant';
import {CognitoService} from '../../../core/auth/cognito.service';
import {HttpErrorService} from '../../../core/services/http-error.service';
import {SIGN_IN_STATE} from '../organization-login.component';
import {ControlMessagesComponent} from '../../../components/control-messages/control-messages.component';
import {ValidationService} from '../../../shared/services/validation.service';

export interface IResetPasswordForm {
  code?: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}
@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule, CustomInputComponent, ButtonComponent, ControlMessagesComponent],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent implements OnDestroy {
  @Input() public clientId: string = '';
  @Input() public userName: string | undefined;
  @Input() public resetWithCode!: boolean;
  @Output() setSignInState: EventEmitter<string> = new EventEmitter<string>();
  @Output() signInOuput: EventEmitter<any> = new EventEmitter<any>();
  @Output() routeToApplicationGettingDefaultOrgDetails: EventEmitter<void> = new EventEmitter<void>();

  LABEL_CONSTANT = LABEL_CONSTANT;
  resetPasswordForm: FormGroup<IResetPasswordForm>;

  constructor(
    private _cognitoService: CognitoService,
    private _httpErrorService: HttpErrorService,
    private _validationService: ValidationService,
  ) {
    this.resetPasswordForm = new FormGroup<IResetPasswordForm>(
      {
        password: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required),
      },
      {validators: [ValidationService.passwordMatchValidator]},
    );
  }

  ngOnInit(): void {
    if (this.resetWithCode) {
      this.resetPasswordForm.addControl('code', new FormControl(null, Validators.required));
    }
  }

  public onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      if (this.resetWithCode) {
        this.resetPassword();
      } else {
        this.confirmSignInWithNewPassword();
      }
    } else {
      // shows validation error if the form is not valid
      this._validationService.validateAllFormFields(this.resetPasswordForm);
    }
  }

  public switchToLoginFlow(): void {
    this.setSignInState.emit(SIGN_IN_STATE.SIGN_IN);
  }

  private resetPassword(): void {
    const {code, confirmPassword} = this.resetPasswordForm.getRawValue();
    this._cognitoService
      .confirmResetPassword(this.userName as string, confirmPassword as string, code as string)
      .then(() => {
        this.switchToLoginFlow();
      })
      .catch((error: Error) => {
        this._httpErrorService.httpError.set(error.message);
      });
  }

  private confirmSignInWithNewPassword(): void {
    const {confirmPassword} = this.resetPasswordForm.value;
    this._cognitoService
      .handleUpdatePassword(confirmPassword as string)
      .then((result) => {
        if (result?.nextStep?.signInStep === LOGIN_CONSTANT.CONTINUE_SIGN_IN_WITH_TOTP_SETUP) {
          this.signInOuput.emit(result.nextStep);
          this.setSignInState.emit(SIGN_IN_STATE.MFA_SETUP);
        } else {
          this.routeToApplicationGettingDefaultOrgDetails.emit();
        }
      })
      .catch((error: Error) => {
        this._httpErrorService.httpError.set(error.message);
      });
  }

  ngOnDestroy(): void {
    this._httpErrorService.httpError.set('');
  }
}
