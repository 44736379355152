import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {CognitoService} from '../../../core/auth/cognito.service';
import {HttpErrorService} from '../../../core/services/http-error.service';
import {CustomInputComponent} from '../../../components/custom-input/custom-input.component';
import {ButtonComponent} from '../../../components/button/button.component';
import {QRCodeModule} from 'angularx-qrcode';
import {NgIf} from '@angular/common';
import {confirmSignIn} from 'aws-amplify/auth';
import {AppAuthorizationService} from '../../../core/auth/app-authorization.service';
import {CognitoOrgIdService} from '../../../core/auth/cognito-org-id.service';
import {updateMFAPreference} from 'aws-amplify/auth';
import {
  ARIA_CONSTANT, HTTP_ERROR_CONSTANT, LABEL_CONSTANT,
  MFA_SETUP_CONSTANT,
  PLACEHOLDER_CONSTANT,
  VALIDATION_ERROR_CONSTANT
} from '../../../constant/constant';

@Component({
  selector: 'app-mfa-setup',
  standalone: true,
  imports: [
    CustomInputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    QRCodeModule,
    NgIf
  ],
  templateUrl: './mfa-setup.component.html',
  styleUrl: './mfa-setup.component.scss'
})
export class MfaSetupComponent {

  @Output() setSignInState: EventEmitter<string> = new EventEmitter<string>();
  @Input() totpSetupDetails: any | null = null;
  @Output() routeToApplicationGettingDefaultOrgDetails: EventEmitter<void> = new EventEmitter<void>();

  ARIA_CONSTANT = ARIA_CONSTANT;
  MFA_SETUP_CONSTANT = MFA_SETUP_CONSTANT;
  VALIDATION_ERROR_CONSTANT = VALIDATION_ERROR_CONSTANT;
  PLACEHOLDER_CONSTANT = PLACEHOLDER_CONSTANT;
  LABEL_CONSTANT = LABEL_CONSTANT;
  mfaSetupForm: FormGroup;
  qrCodeUrl: URL | null = null;
  isVerifying = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _cognitoService: CognitoService,
    private _httpErrorService: HttpErrorService,
    private _appAuthorizationService: AppAuthorizationService,
    private _cognitoOrgIdService: CognitoOrgIdService
  ) {
    this.mfaSetupForm = this._formBuilder.group({
      verificationCode: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{6}$')
      ]]
    });
  }

  async ngOnInit() {
    try {
      this.qrCodeUrl = this.totpSetupDetails?.totpSetupDetails.getSetupUri(
        `CareConnectNX`,
        this.totpSetupDetails?.username
      ) as URL;
    } catch (error) {
      this._httpErrorService.httpError.set(HTTP_ERROR_CONSTANT.MFA_FAILED);
    }
  }

  async onVerify(): Promise<void> {
    if (this.mfaSetupForm.valid && !this.isVerifying) {
      this.isVerifying = true;
      try {
        const code = this.mfaSetupForm.get('verificationCode')?.value;
        const isVerified = await confirmSignIn({challengeResponse: code})

        if (isVerified.nextStep.signInStep === 'DONE') {
          await updateMFAPreference({ totp: 'PREFERRED' });
          this.routeToApplicationGettingDefaultOrgDetails.emit();
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          this._httpErrorService.httpError.set(error.message);
        }
      } finally {
        this.isVerifying = false;
      }
    }
  }
}
